<template>
  <LayoutParametrage>
    <template #current_page>
      <span>Liste des Templates</span>
    </template>
    <template #add_button>
      <button
        @click="toggleCreateModal()"
        class="btn-green px-6 py-2 sm:w-full md:mt-8 mr-4"
      >
        Ajouter une template
      </button>
    </template>
    <template #modal>
      <pro-card>
        <pro-table-row :isHeader="true">
          <pro-table-header-cell
            v-for="(sort, index) in sort_list"
            :key="index"
            :class="sort.classes"
            :sortingBy="sorting_by"
            :hasSort="sort.sortKey ? true : false"
            :title="sort.title"
            :sortKey="sort.sortKey"
            @setSortingBy="setSortingBy"
            @orderBy="orderBy"
          />
        </pro-table-row>
        <InfiniteScrollContainer
          :isLoading="isFetching"
          :containerScroll="false"
          :fetchingAllowed="lastPage > currentPage"
          @fetch="getTemplates()"
        >
          <pro-table-row
            v-for="(template, index) in templates"
            :key="index"
            @handleClick="openTemplate(template)"
            class="relative cursor-pointer hover:bg-promy-gray-100"
          >
            <pro-table-cell class="w-1/5">
              <div v-html="template.name"></div>
            </pro-table-cell>
            <pro-table-cell class="w-1/5">
              {{ template.type.name }}
            </pro-table-cell>
            <pro-table-cell class="w-1/5">
              {{ template.version }}
            </pro-table-cell>
            <pro-table-cell class="w-1/5">
              <pro-check-toggle
                v-model="template.default"
                @input="toggleDefault(template)"
              ></pro-check-toggle>
            </pro-table-cell>
            <pro-table-cell class="w-1/5">
              {{ template.created_at | filterDate }}
            </pro-table-cell>
            <pro-table-cell class="w-1/5">
              <div class="flex space-x-2">
                <button
                  @click="update($event, template)"
                  class="bg-blue-500 rounded-full p-2 cursor-pointer sm:flex sm:items-center sm:px-3 sm:my-2"
                >
                  <img src="/images/update.svg" alt="" class="w-6 sm:mr-2" />
                  <span class="hidden sm:inline-block text-white">
                    Modifier
                  </span>
                </button>
                <div
                  class="bg-red-500 rounded-full p-2 mr-3 cursor-pointer flex items-center justify-center"
                  @click="toggleDeleteModal($event, template.id)"
                >
                  <img src="/images/trash.svg" alt="" class="w-6 h-6" />
                </div>
              </div>
            </pro-table-cell>
          </pro-table-row>
        </InfiniteScrollContainer>
      </pro-card>
      <!--  -->
      <Modal v-if="showDeleteModal">
        <slot slot="body">
          <p class="text-promy-gray-700 text-sm font-arial py-4">
            Êtes-vous sûr de vouloir supprimer cette template ?
          </p>
        </slot>
        <slot slot="footer">
          <button
            class="btn-green px-6 py-2 sm:w-full md:mt-8 mr-4"
            @click="toggleDeleteModal"
          >
            Non
          </button>
          <button
            class="font-extrabold text-white uppercase border-2 border-solid rounded-full font-main hover:bg-promy-red hover:border-promy-red border-promy-red-100 bg-promy-red-100 px-6 py-2 sm:w-full md:mt-8"
            @click="remove()"
          >
            Oui
          </button>
        </slot>
      </Modal>
      <Modal v-if="showCreateModal">
        <slot slot="header">
          <h1 class="text-promy-purple-400 font-black text-xl font-main">
            Choisissez le type de template :
          </h1>
        </slot>
        <slot slot="body">
          <div class="grid grid-cols-2 gap-4">
            <router-link
              v-for="(type, index) in types"
              :key="index"
              :to="`/parametrage/bilan-financier/ajouter/${type.id}`"
              class="border-2 border-promy-green-400 p-8 rounded-lg font-bold text-promy-green-400 text-xl text-center cursor-pointer"
            >
              {{ type.name }}
            </router-link>
          </div>
        </slot>
        <slot slot="footer">
          <button
            class="font-extrabold text-white uppercase border-2 border-solid rounded-full font-main hover:bg-promy-red hover:border-promy-red border-promy-red-100 bg-promy-red-100 px-6 py-2 sm:w-full md:mt-8"
            @click="toggleCreateModal"
          >
            Annuler
          </button>
        </slot>
      </Modal>
    </template>
  </LayoutParametrage>
</template>

<script>
import TopicSheet from '../../../../components/BilanFinancier/TopicSheet.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    TopicSheet,
  },
  data() {
    return {
      page: 1,
      showDeleteModal: false,
      showCreateModal: false,
      selectedTemplateId: null,
      sort_list: [
        {
          title: 'Nom',
          sortKey: 'name',
          classes: 'w-1/5',
        },
        {
          title: 'Type',
          sortKey: 'bilan_template_type_id',
          classes: 'w-1/5',
        },
        {
          title: 'Version',
          sortKey: 'version',
          classes: 'w-1/5',
        },
        {
          title: 'Par défault ?',
          sortKey: 'default',
          classes: 'w-1/5',
        },
        {
          title: 'Date de création',
          sortKey: 'created_at',
          classes: 'w-1/5',
        },
        {
          title: '',
          sortKey: '',
          classes: 'w-1/5',
        },
      ],
      sorting_by: '',
    }
  },
  mounted() {
    this.$store.dispatch('bilanFinancier/getTypes')
  },
  computed: {
    ...mapGetters({
      templates: 'bilanFinancier/getTemplates',
      types: 'bilanFinancier/getTypes',
      isFetching: 'bilanFinancier/isLoading',
      lastPage: 'bilanFinancier/getLastPage',
      currentPage: 'bilanFinancier/getCurrentPage',
    }),
  },
  methods: {
    getTemplates() {
      this.$store.dispatch('bilanFinancier/getTemplates')
    },
    toggleDeleteModal(e = null, templateId = null) {
      if (e) e.stopPropagation()
      this.selectedTemplateId = templateId
      this.showDeleteModal = !this.showDeleteModal
    },
    toggleCreateModal() {
      this.showCreateModal = !this.showCreateModal
    },
    toggleDefault(template) {
      this.$store.dispatch('bilanFinancier/toggleDefault', template.id)
    },
    openTemplate(template) {
      this.$store.commit('bilanFinancier/SET_SELECTED_TEMPLATE', template)
      this.$router.push(`/parametrage/bilan-financier/template/${template.id}`)
    },
    update(e, template) {
      if (e) e.stopPropagation()
      this.$store.commit('bilanFinancier/SET_SELECTED_TEMPLATE', template)
      this.$router.push(`/parametrage/bilan-financier/modifier/${template.id}`)
    },
    remove() {
      this.$store.dispatch(
        'bilanFinancier/deleteTemplate',
        this.selectedTemplateId,
      )
      this.toggleDeleteModal()
    },
    setSortingBy(val) {
      this.sorting_by = val
    },
    orderBy(key, order) {
      this.$store.dispatch('bilanFinancier/orderBy', { key, order })
    },
  },
}
</script>
